$(function(){
    if($('.gantt').length == 0) return;

    console.log('gantt inited');
    $('.content').on('mousedown', '.gantt-task-grab', initResize);

    var elStart, $el, $grab, startX, screenW, elEnd, step, clientX, typeEnd;

    var timelineStartTimestamp = parseInt($('.gantt-timeline').attr('data-start-date'));
    var timelineEndTimestamp = parseInt($('.gantt-timeline').attr('data-end-date'));

    function initResize(e) {
        $('#tasks').removeAttr('wire:poll.10s');
        $(this).addClass('active');
        typeEnd = $(this).is('.gantt-task-grab-end');
        $grab = $(this);
        $el = $(this).parent();
        startX = e.clientX;
        clientX = e.clientX;
        screenW = $('.gantt-timeline').width();
        elStart = parseFloat($el.attr('data-start'));
        elEnd = parseFloat($el.attr('data-end'));
        // elStartW = parseFloat($el.attr('data-width'));
        step = parseFloat($el.attr('data-step'));

        window.addEventListener('mousemove', Resize, false);
        window.addEventListener('mouseup', stopResize, false);

        console.log('Screen: ', screenW);
        console.log('elStart: ', elStart);
        // console.log('elStartW: ', elStartW);
    }

    //resize the element
    function Resize(e) {
        clientX = e.clientX;
        resizeEl();
    }

    //on mouseup remove windows functions mousemove & mouseup
    function stopResize(e) {
        window.removeEventListener('mousemove', Resize, false);
        window.removeEventListener('mouseup', stopResize, false);

        resizeEl(true);

        $('.gantt-task-grab.active').removeClass('active');
    }

    function resizeEl(stop = false) {
        if (Math.abs(clientX - startX) < 5) return;

        if (typeEnd) {
            var wDiff = 100 * (clientX - startX) / screenW;
            var newW = (elEnd - elStart + wDiff);

            var newL = elStart;

            if (newW < step) {
                newW = step;
            }

            if (stop) {
                newW = step * Math.round(newW / step);
            }

            var timestamp = getTimestampByPos(newL + step * Math.round((newW) / step));
            var date = getDateByPos(timestamp);
            $grab.attr('data-title', date);
        } else {
            var lDiff = 100 * (clientX - startX) / screenW;
            var newL = (elStart + lDiff);

            if (stop) {
                newL = step * Math.round(newL / step);
            }

            var newW = elEnd - newL;

            if (newW < step) {
                newW = step;
                newL = elEnd - step;
            }

            var timestamp = getTimestampByPos(step * Math.round(newL / step) + step * 0.5);
            var date = getDateByPos(timestamp);
            $grab.attr('data-title', date);
        }


        $el.css('left', newL + '%');
        $el.attr('data-start', newL);

        $el.css('width', newW + '%');
        $el.attr('data-width', newW);

        $el.attr('data-end', newL + newW);

        if (stop) {
            saveGanttTime($el.attr('data-id'), timestamp, typeEnd);
        }
    }

    function saveGanttTime(id, timestamp, typeEnd) {
        var data = {
            '_token': $('[name="csrf-token"]').attr('content'),
        }

        if (typeEnd) {
            data.finished_at = timestamp;
        } else {
            data.started_at = timestamp;
        }

        $.ajax({
            'method': 'POST',
            'url': '/task/' + id + '/dates/update',
            'data': data,
        }).done(function(msg) {
            snackbar('Date updated');
            $('#tasks').attr('wire:poll.10s', '');
        });
    }

    function getTimestampByPos(pos) {
        var days = timelineEndTimestamp - timelineStartTimestamp;
        return timelineStartTimestamp + parseInt(days * pos / 100);
    }

    function getDateByPos(timestampByPos) {
        var d = new Date(timestampByPos * 1000);
        return d.getDate() + '. ' + (d.getMonth() + 1) + '.';
    }
});
