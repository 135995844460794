window.snackbar = function (message, details = false, type = false, icon = false){

    var template = `
        <div class="snackbar alert border-0">
            <button type="button" class="btn-close btn-close close" data-bs-dismiss="alert"></button>

            <div class="alert-content">`;

    if(icon){
        template += `<i class="alert-ico ${icon} text-${type}"></i>`;
    }

    template += `
                <div>
                    <div class="alert-title">${message}</div>`
    if(details){
        template += `<div class="alert-text">${details}</div>`
    }

    template += `
                </div>
            </div>
        </div>`;

    var $snackbar = $(template);

    $('.snackbar-container').prepend($snackbar);
    setTimeout(function() {
        $snackbar.remove();
    }, 40000);
}

window.addEventListener('snackbar', function(e){
    snackbar(e.detail.message, e.detail.details || false, e.detail.type || false, e.detail.icon || false);
});

window.copyToClipboard = function (text, el = false) {
    if (navigator.clipboard && window.isSecureContext) {
       navigator.clipboard.writeText(text).then(() => {})
            .catch(() => {snackbar('something went wrong');});
    } else {
        let textArea = document.createElement("textarea");
        textArea.value = text;
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        new Promise((res, rej) => {
            document.execCommand('copy') ? res() : rej();
            textArea.remove();
        });
    }
    snackbar('Copied to clipboard');
}

window.setCookie = function(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

window.getCookie = function(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

window.eraseCookie = function(name) {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

window.toggleDatkTheme = function(){
    if($('#datk-theme').is(':checked')){
        $('html').attr('data-bs-theme', 'dark');
        setCookie('theme', 'dark', 360);
    }else{
        $('html').attr('data-bs-theme', 'light');
        setCookie('theme', 'light', 360);
    }
}

window.toggleAppnav = function(){
    if($('.app-nav').hasClass('app-nav-full')){
        $('.app-nav').removeClass('app-nav-full')
        setCookie('appnav', 'closed', 360);
    }else{
        $('.app-nav').addClass('app-nav-full')
        setCookie('appnav', 'open', 360);
    }
}

window.initTooltips = function(){
    const tooltipTriggerList = document.querySelectorAll('[data-tooltip]:not([data-bs-original-title])')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
}

window.debounce = (mainFunction, delay = 100) => {
    // Declare a variable called 'timer' to store the timer ID
    let timer;

    // Return an anonymous function that takes in any number of arguments
    return function (...args) {
      // Clear the previous timer to prevent the execution of 'mainFunction'
      clearTimeout(timer);

      // Set a new timer that will execute 'mainFunction' after the specified delay
      timer = setTimeout(() => {
        mainFunction(...args);
      }, delay);
    };
};

window.addEventListener('clearSummernote', function(event){
    localStorage.removeItem(event.detail);
});


