$(function () {
    initTooltips();

    $('.dropdown-menu[data-dropdown="keep"]').on("click.bs.dropdown", function (e) {
        if ($(this).parent().hasClass("show")) {
            var target = $(e.target);

            if (!(target.hasClass("dropdown-close") || target.hasClass("CloseDropDown") || target.parents(".CloseDropDown").length)) {
                e.stopPropagation();
            }
        }
    });

    // $(".btn").on('mouseup', function(){
    //     $(this).trigger('blur');
    // });
});


var runningTimerInterval = 0;
// task time tracker
$(function(){
    var running = getRunningTimer();

    if(running.id != 0){
        startTimer(running);
    }

    $('.task-timer-start').on('click', function(){
        running = getRunningTimer();
        // pokud je nektery spusteny
        if(running.id != 0){
            stopTimer(running);
        }

        // start new timer
        $timer = $(this).closest('.task-timer');
        running.id = $timer.attr('data-timer');
        running.value = $timer.attr('data-value');
        running.start = Math.floor(new Date().getTime()/1000);
        startTimer(running);
    });

    $('.task-timer-stop').on('click', function(){
        running = getRunningTimer();
        stopTimer(running);
    });
});

function getRunningTimer(){
    return localStorage.getItem('timer') ? JSON.parse(localStorage.getItem('timer')): {id: 0, start: 0, value: 0};
}

function startTimer(timer){
    localStorage.setItem('timer', JSON.stringify(timer));

    $timer = $('[data-timer="'+timer.id+'"]');
    $timer.addClass('active');
    $timerOutput = $timer.find('.task-timer-value');

    setTimerText($timerOutput, timer);
    runningTimerInterval = setInterval(function(){
        setTimerText($timerOutput, timer);
    }, 1000);
}

function setTimerText($timerOutput, timer){
    var now = Math.floor(new Date().getTime()/1000);
    var totalSeconds = (timer.value * 60) + (now - timer.start);

    $timerOutput.text(toHHMMSS(totalSeconds));
}

var toHHMMSS = (secs) => {
    var sec_num = parseInt(secs, 10)
    var hours   = Math.floor(sec_num / 3600)
    var minutes = Math.floor(sec_num / 60) % 60
    var seconds = sec_num % 60

    return [hours,minutes,seconds]
        .map(v => v < 10 ? "0" + v : v)
        .join(":")
}

function stopTimer(timer){
    var now = Math.floor(new Date().getTime()/1000);
    var tracked = (now - timer.start);

    $.ajax({
        'method': 'POST',
        'url': 'worklog/store/'+timer.id,
        'data': {
            '_token': $('[name="csrf-token"]').attr('content'),
            'start': timer.start,
            'time_spend': Math.floor(tracked/60)+1,
        },
    }).done(function(msg){
        snackbar('Tracked time saved');
    });


    if(runningTimerInterval){
        clearInterval(runningTimerInterval);
    }

    $('[data-timer="'+timer.id+'"]').removeClass('active');

    localStorage.removeItem('timer');
}

$(function(){
    $('.dropdown-input').on('input', function(){
        var $container = $(this).parent();
        var search = $(this).val().toLocaleLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        if(search == ''){
            $container.find('.dropdown-item').show();
            $container.find('.dropdown-divider').show();
        }else{
            $container.find('.dropdown-item').each(function(){
                var text = $(this).attr('data-search').toLocaleLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                if(text.indexOf(search) >= 0){
                    $(this).show();
                }else{
                    $(this).hide();
                }
            });
            $container.find('.dropdown-divider').hide();
        }

    });

    $('.content').on('click', '.quote-toggle', function(){
        $(this).toggleClass('expanded');
    });

    if (window.location.hash) {
        var hash = window.location.hash;
        console.log(hash);

        if ($(hash).length) {
            $('.layout-content').animate({
                scrollTop: $(hash).offset().top
            }, 900, 'swing');
        }
    }
});
